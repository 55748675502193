import React, { useEffect, useRef, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'sonner';
import { navItems } from './utils/data/navItems';
import SideNav from './newUI/merchant/components/sidenav';
import Header from './newUI/merchant/components/header';
import { FaTimes } from 'react-icons/fa';
import Card from './newUI/merchant/components/card';
import LinearProgress from '@mui/material/LinearProgress';
import { useAPI } from './apis/api_context';
import { useAuth } from './apis/auth_context';
import InputField from './newUI/merchant/components/input_field';
import Button from './newUI/merchant/components/button';

const Layout = ({ children, title, description }) => {
	const profileComplete = useSelector((state) => state.profileComplete);

	//TODO: Show Subscription status across app
	const { subscription } = useSelector((state) => state.user);

	const userData = useSelector((state) => state.user);
	const location = useLocation();
	const modalRef = useRef(null);
	const apiservice = useAPI();
	const { refreshUserData } = useAuth();

	const [isProductModalOpen, setIsProductModalOpen] = useState(false);
	const [isSettlementModalOpen, setIsSettlementModalOpen] = useState(false);
	const [formData, setFormData] = useState({
		bankName: '',
		bankCode: '',
		accountNumber: '',
		accountName: '',
	});
	const [bankList, setBankList] = useState([]);
	const [filteredBanks, setFilteredBanks] = useState([]);
	const [showDropdown, setShowDropdown] = useState(false);
	const [loading, setLoading] = useState(false);

	const getRouteTitle = (path) => {
		const matchedNavItem = navItems.find(
			(item) => path === item.to || path.startsWith(item.to),
		);
		return matchedNavItem ? `Manage your ${matchedNavItem.title}` : null;
	};

	const defaultTitle = (() => {
		const routeTitle = getRouteTitle(location.pathname);

		if (location.pathname === '/' || location.pathname === '/overview') {
			return `Hey ${userData?.firstName || 'User'} 👋`;
		}

		return routeTitle || `Hey ${userData?.firstName || 'User'} 👋`;
	})();

	const handleBankInput = (value) => {
		setFormData((prev) => ({ ...prev, bankName: value }));
		const filtered = bankList.filter((bank) =>
			bank.name.toLowerCase().includes(value.toLowerCase()),
		);
		setFilteredBanks(filtered);
		setShowDropdown(true);
	};

	const validateAccountNumber = async (accountNumber) => {
		setLoading(true);
		try {
			const response = await apiservice.resolveAccountName({
				accountNumber,
				bankCode: formData.bankCode,
			});
			if (response.status === 200) {
				setFormData((prev) => ({
					...prev,
					accountName: response.data.account_name,
				}));
			} else {
				toast.error('Invalid account number');
			}
		} catch (error) {
			toast.error('Failed to validate account number');
		} finally {
			setLoading(false);
		}
	};

	const handleSaveBankDetails = async () => {
		try {
			const { accountNumber, accountName, bankCode, bankName } = formData;

			if (!accountNumber || !accountName || !bankCode || !bankName) {
				toast.error('Please complete all banking details');
				return;
			}

			const response = await apiservice.updateBankDetails({
				accountNumber,
				accountName,
				bankCode,
				bankName,
			});

			if (response.status === 200) {
				toast.success('Banking details updated successfully!');
				refreshUserData();
				setIsSettlementModalOpen(false);
			}
		} catch (error) {
			toast.error('Failed to update banking details.');
		}
	};

	const handleBankSelect = (bank) => {
		setFormData((prev) => ({
			...prev,
			bankName: bank.name,
			bankCode: bank.code,
		}));
		setShowDropdown(false);
	};

	useEffect(() => {
		const fetchBankList = async () => {
			try {
				const banks = await apiservice.getBanksList();
				setBankList(banks);
			} catch (error) {
				toast.error('Failed to fetch bank list');
			}
		};
		fetchBankList();
	}, [apiservice]);

	const cards = [
		!profileComplete.storeSetUp && {
			icon: {
				component: (
					<i className='fi fi-sr-store-alt h-6 w-6 inline-flex items-center justify-center text-red-500'></i>
				),
				bgColor: 'bg-red-100',
			},
			title: 'Set Up Store',
			description: 'Complete the setup of your store to start selling.',
			actionText: 'Set Up Now',
			onClick: () => {},
		},
		!profileComplete.productsAdded && {
			icon: {
				component: (
					<i className='fi fi-sr-box-open h-6 w-6 inline-flex items-center justify-center text-blue-500'></i>
				),
				bgColor: 'bg-blue-100',
			},
			title: 'Add Products',
			description: 'Add products to your store to start selling.',
			actionText: 'Add Products',
			onClick: () => {
				setIsProductModalOpen(true);
			},
		},
		!profileComplete.settlementAccount && {
			icon: {
				component: (
					<i className='fi fi-sr-wallet-arrow h-6 w-6 inline-flex items-center justify-center text-green-500'></i>
				),
				bgColor: 'bg-green-100',
			},
			title: 'Set Up Settlement Account',
			description: 'Provide your bank details to receive payouts.',
			actionText: 'Set Up Account',
			onClick: () => {
				setIsSettlementModalOpen(true);
			},
		},
		!profileComplete.emailVerified && {
			icon: {
				component: (
					<i className='fi fi-sr-newsletter-subscribe h-6 w-6 inline-flex items-center justify-center text-yellow-500'></i>
				),
				bgColor: 'bg-yellow-100',
			},
			title: 'Verify Email',
			description: 'Verify your email address to complete your profile.',
			actionText: 'Verify Email',
			onClick: () => {},
		},
	].filter(Boolean);

	return (
		<HelmetProvider>
			<div className='flex min-h-screen h-screen overflow-hidden'>
				<Toaster position='top-right' />
				<SideNav
					merchantData={userData}
					subscription={subscription}
				/>
				<main className='flex-1 bg-[#ffffff] overflow-hidden h-screen flex flex-col'>
					<Helmet>
						<title>
							{title || defaultTitle} | Merchant Dashboard
						</title>
						<meta
							name='description'
							content={
								description ||
								`${
									title || defaultTitle
								} - Dashboard Overview and Management`
							}
						/>
					</Helmet>

					<Header
						merchantData={userData}
						pageTitle={title || defaultTitle}
						isProductModalOpen={isProductModalOpen}
						setIsProductModalOpen={setIsProductModalOpen}
						subscription={subscription}
						refreshUserData={refreshUserData}
					/>

					{!profileComplete.profileIsComplete && (
						<div className='p-6'>
							<div className='mb-3 text-[14px] text-gray-500 font-medium '>
								Complete your profile
							</div>
							<div className='flex space-x-4'>
								{cards.map((card, index) => (
									<Card
										key={index}
										{...card}
									/>
								))}
							</div>
						</div>
					)}

					<div className='flex-1 px-[24px] overflow-auto no-scrollbar'>
						{children}
					</div>

					{isSettlementModalOpen && (
						<div
							className='fixed inset-0 bg-primary bg-opacity-50 z-50 flex items-center justify-center modal-overlay'
							onClick={() => setIsSettlementModalOpen(false)}>
							<div
								onClick={(e) => e.stopPropagation()}
								ref={modalRef}
								className='bg-white rounded-lg max-w-md w-full mx-4 p-4 relative'>
								<div className='flex items-center justify-between mb-4'>
									<h2 className='text-xl font-semibold '>
										Set Up Settlement Account
									</h2>
									<button
										onClick={() =>
											setIsSettlementModalOpen(false)
										}
										className='bg-gray-100 text-primary p-2 rounded-full'>
										<FaTimes />
									</button>
								</div>

								{/* Bank Details Form */}
								<div className='space-y-4'>
									<div className='relative'>
										<InputField
											value={formData.bankName}
											handleValue={handleBankInput}
											type='text'
											placeholder='Type to search your Bank'
										/>
										{showDropdown &&
											filteredBanks.length > 0 && (
												<ul className='absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto'>
													{filteredBanks.map(
														(bank, index) => (
															<li
																key={index}
																className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
																onClick={() =>
																	handleBankSelect(
																		bank,
																	)
																}>
																{bank.name}
															</li>
														),
													)}
												</ul>
											)}
									</div>

									<InputField
										value={formData.accountNumber}
										handleValue={(value) => {
											setFormData((prev) => ({
												...prev,
												accountNumber: value,
											}));
											if (value.length === 10) {
												validateAccountNumber(value);
											}
										}}
										type='text'
										placeholder='Enter your account number'
									/>
									{loading && <LinearProgress />}
									{formData.accountName && (
										<p className='text-basegreen capitalize text-[14px] mb-4'>
											{formData.accountName}
										</p>
									)}
									<div className='flex space-x-4 justify-end'>
										<Button
											label='Cancel'
											type='secondary'
											onClick={() =>
												setIsSettlementModalOpen(false)
											}
										/>
										<Button
											label='Save'
											onClick={handleSaveBankDetails}
											disabled={
												!formData.accountNumber ||
												!formData.accountName ||
												!formData.bankCode ||
												!formData.bankName ||
												loading
											}
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</main>
			</div>
		</HelmetProvider>
	);
};

export default Layout;
