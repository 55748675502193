import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Avatar from './avatar';
import DropDown from '../../../assets/images/dropDown.svg';
import { useAuth } from '../../../apis/auth_context';
import { navItems } from '../../../utils/data/navItems';
import { paths } from '../../../routes';

const SideNav = ({ merchantData, subscription }) => {
	const [isOpen, setIsOpen] = useState(false);
	// Initialize isExpanded from localStorage, defaulting to true if not set
	const [isExpanded, setIsExpanded] = useState(() => {
		const saved = localStorage.getItem('sidenavExpanded');
		return saved !== null ? JSON.parse(saved) : true;
	});

	const { logoutUser } = useAuth();
	const dropdownRef = useRef(null);
	const navigate = useNavigate();

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	// Modified toggle function to save state to localStorage
	const toggleSidenav = () => {
		const newState = !isExpanded;
		setIsExpanded(newState);
		localStorage.setItem('sidenavExpanded', JSON.stringify(newState));
	};

	const menuItems = [
		{
			label: 'Manage Profile',
			action: () => {
				navigate(paths.PROFILE);
				setIsOpen(false);
			},
		},
		{
			label: 'Logout',
			action: () => {
				logoutUser();
				setIsOpen(false);
			},
		},
	];

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	if (!merchantData) {
		return <div>Loading...</div>;
	}

	return (
		<div
			className={`${
				isExpanded ? 'w-64' : 'w-20'
			} relative h-screen bg-white p-4 border-r transition-all duration-300`}>
			{/* Toggle Button */}
			<button
				onClick={toggleSidenav}
				className='absolute -right-3 top-6 bg-white rounded-full p-1 border shadow-md hover:bg-gray-50'>
				<svg
					className={`w-4 h-4 transform ${
						isExpanded ? 'rotate-0' : 'rotate-180'
					} transition-transform duration-300`}
					fill='none'
					stroke='currentColor'
					viewBox='0 0 24 24'>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						d='M15 19l-7-7 7-7'
					/>
				</svg>
			</button>

			{/* Header Section */}
			<div
				className={`flex items-center ${
					isExpanded ? 'gap-3' : 'justify-center'
				} cursor-pointer mb-12`}
				onClick={toggleDropdown}>
				<div className='w-[40px] h-[40px] flex-shrink-0'>
					<Avatar
						firstName={merchantData?.store?.storeName || ''}
						imageSrc={merchantData?.store?.settings?.logo}
					/>
				</div>

				{isExpanded && (
					<>
						<div className='flex-col space-y-1 w-2/3 overflow-clip'>
							<h1 className='text-[16px] truncate font-[500] leading-[18px] -tracking-[3%] text-primary'>
								{merchantData?.store?.storeName || 'Store Name'}
							</h1>
						</div>
						<button>
							<img
								src={DropDown}
								alt='Drop down icon'
								className='w-[16px] h-[16px] cursor-pointer'
							/>
						</button>
					</>
				)}
			</div>

			{/* Navigation Items */}
			{navItems.map((navItem, index) => (
				<div
					key={index}
					className='mb-[24px]'>
					{navItem.items ? (
						<>
							{isExpanded && (
								<p className='text-xs text-[#A4B1B1] uppercase mb-3'>
									{navItem.title}
								</p>
							)}
							{navItem.items.map((item, itemIndex) => (
								<NavItem
									key={itemIndex}
									label={item.title}
									to={item.to}
									icon={item.activeIcon}
									outlinedIcon={item.inactiveIcon}
									isExpanded={isExpanded}
								/>
							))}
						</>
					) : (
						<NavItem
							label={navItem.title}
							to={navItem.to}
							activeIcon={navItem.activeIcon}
							inactiveIcon={navItem.inactiveIcon}
							exact={navItem.exact}
							isExpanded={isExpanded}
						/>
					)}
				</div>
			))}

			{/* Profile Dropdown */}
			{isOpen && (
				<div
					ref={dropdownRef}
					className={`bg-white w-[240px] fixed top-5 ${
						isExpanded ? 'left-[30px]' : 'left-[80px]'
					} z-50 border border-borderNeutral rounded-[8px] flex flex-col items-start justify-center gap-7 px-4 py-3 font-firma shadow-lg`}>
					<div className='flex flex-col'>
						<div className='w-[40px] h-[40px]'>
							<Avatar
								firstName={merchantData?.store?.storeName || ''}
								imageSrc={merchantData?.store?.settings?.logo}
							/>
						</div>
						<h1 className='mt-4 text-[16px] font-[500] leading-[18px] -tracking-[3%] text-primary'>
							{merchantData?.store?.storeName || 'Store Name'}
						</h1>
						<p className='text-[14px] mt-1 font-[400] leading-[16px] -tracking-[3%] text-gray-500'>
							{merchantData?.store?.contactEmail || 'Email'}
						</p>
					</div>
					<div className='flex flex-col items-center gap-4'>
						{menuItems.map((item, index) => (
							<button
								key={index}
								className='w-[205px] h-[50px] bg-bgNeutral rounded-[4px] p-3 text-[500] text-primary text-[16px] leading-[18px] -tracking-[3%] text-left'
								onClick={() => item.action()}>
								{item.label}
							</button>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

const NavItem = ({
	label,
	to,
	activeIcon,
	inactiveIcon,
	exact,
	isExpanded,
}) => {
	return (
		<NavLink
			onClick={() => {
				localStorage.removeItem('activeTab');
				localStorage.removeItem('currentPage');
			}}
			to={to}
			exact={exact ? 'true' : undefined}
			className={({ isActive }) =>
				`flex items-center ${
					isExpanded
						? 'space-x-4 px-[16px]'
						: 'justify-center px-[8px]'
				} py-[12px] rounded-xl cursor-pointer transition-colors ${
					isActive
						? 'bg-[#0B6E4F] text-[#FFFFFF]'
						: 'text-[#7B8783] bg-transparent font-light'
				}`
			}
			title={!isExpanded ? label : undefined}>
			{({ isActive }) => (
				<div
					className={`flex items-center ${
						isExpanded ? 'space-x-2' : 'justify-center'
					}`}>
					{isActive ? activeIcon : inactiveIcon}
					{isExpanded && <span className='text-center'>{label}</span>}
				</div>
			)}
		</NavLink>
	);
};

export default SideNav;
