import React, { useEffect, useMemo, useState } from 'react';
import { FaTimes, FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'sonner';
import InputField from '../../components/input_field';
import { useAPI } from '../../../../apis/api_context';
import { useAuth } from '../../../../apis/auth_context';
import Button from '../button';
import ToggleButton from './toggleButton';
import Homegoods from './homegoods';
import Clothing from './clothing';
import Footwear from './footwear';
import Electronics from './electronics';

const EditProductModal = ({ closeProductModal, selectedProduct }) => {
	const initialProductData = useMemo(() => {
		const { attributes } = selectedProduct;

		return {
			category: selectedProduct.categoryName,
			name: selectedProduct.name,
			price: selectedProduct.unitPrice,
			description: selectedProduct.description,
			stock: selectedProduct.availableQuantity,
			slug: '',
			productType: attributes.type,
			attributes: {
				...attributes,
				duration: attributes.requireProduction
					? attributes[attributes.type.toLowerCase()]
							?.productionDays || null
					: null,
				gender:
					attributes.type === 'Clothing'
						? attributes.clothing?.gender || 'Choose a gender'
						: attributes.type === 'Footwear'
						? attributes.footwear?.gender || 'Choose a gender'
						: 'Choose a gender',
			},
			images: selectedProduct.images,
		};
	}, [selectedProduct]);

	// console.log('Initial Product Data', initialProductData);

	const { refreshUserData } = useAuth();
	const apiservice = useAPI();

	const [productData, setProductData] = useState(initialProductData);
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState('1');
	const [images, setImages] = useState([]);
	const [imagePreviews, setImagePreviews] = useState([]);
	const [toggleStates, setToggleStates] = useState({
		production: selectedProduct.attributes.requireProduction,
	});
	const [selectedElectronics, setSelectedElectronics] = useState('Laptops');
	const [sizes, setSizes] = useState({
		cloth: ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'Custom'],
		footwear: ['34', '35', '36', '37', '38', '39', '40', 'Custom'],
		laptop: ['5', '6', '13', '14', '15', '16', 'Custom'],
		tv: ['10', '20', '30', '40', '50', '60', '70', 'Custom'],
	});
	const [colors, setColors] = useState(
		productData?.productType === 'Clothing'
			? productData.attributes.clothing.colors
			: productData?.productType === 'Footwear'
			? productData?.attributes.footwear.colors
			: [],
	);

	useEffect(() => {
		if (selectedProduct) {
			setProductData({
				...initialProductData,
				...selectedProduct,
			});
			setImagePreviews(selectedProduct.images.map((img) => img));
			setSearchTerm(selectedProduct.categoryName);
		}
	}, [initialProductData, selectedProduct]);

	const handleProductDataChange = (field, value, isAttribute = false) => {
		setProductData((prevData) => ({
			...prevData,
			...(isAttribute
				? {
						attributes: {
							...prevData.attributes,
							...(field === 'duration'
								? {
										[prevData.productType.toLowerCase()]: {
											...prevData.attributes[
												prevData.productType.toLowerCase()
											],
											productionDays: value,
										},
								  }
								: { [field]: value }),
						},
				  }
				: { [field]: value }),
		}));
	};

	const handleImageUpload = (event) => {
		const files = Array.from(event.target.files).slice(0, 5);
		setImages(files);
		setImagePreviews(files.map((file) => URL.createObjectURL(file)));
		handleProductDataChange('images', files);
	};

	const removeImage = (index) => {
		const updatedImages = images.filter((_, i) => i !== index);
		setImages(updatedImages);
		setImagePreviews(
			updatedImages.map((file) => URL.createObjectURL(file)),
		);
		handleProductDataChange('images', updatedImages);
	};

	const handleSubmit = async () => {
		setLoading(true);
		const formData = new FormData();
		Object.entries(productData).forEach(([key, value]) => {
			formData.append(
				key,
				key === 'attributes' ? JSON.stringify(value) : value,
			);
		});
		images.forEach((image) => formData.append('images', image));

		console.log('FormData payload:');
		for (let [key, value] of formData.entries()) {
			console.log(`${key}:`, value);
		}
		try {
			const response = await apiservice.editProduct(
				selectedProduct._id,
				formData,
			);
			if (response.status === 200) {
				await refreshUserData();
				toast.success('Product updated successfully!', {
					autoClose: 3000,
				});
				closeProductModal();
			}
		} catch (error) {
			toast.error('Failed to update product. Please try again.');
		} finally {
			setLoading(false);
		}
	};
	// console.log('Selected Product', selectedProduct);

	const handleNextStep = () => {
		if (activeTab === '1') {
			setActiveTab('2');
		} else {
			handleSubmit();
		}
	};

	const handleToggleChange = (type, state) => {
		setToggleStates((prevStates) => ({
			...prevStates,
			[type]: state,
		}));
	};
	const [showDropdown, setShowDropdown] = useState(false);
	const [categoryList, setCategoryList] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');

	const handleCategoryInput = (value) => {
		setSearchTerm(value);
		handleProductDataChange('category', value);

		const filtered = categoryList.filter((category) =>
			category.toLowerCase().includes(value.toLowerCase()),
		);

		setFilteredCategories(filtered);
		setShowDropdown(filtered.length > 0);
	};

	const handleCategorySelect = (category) => {
		const selectedCategory = category || searchTerm; // Use input as category if no match
		handleProductDataChange('category', selectedCategory);
		setSearchTerm(selectedCategory);
		setShowDropdown(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (!event.target.closest('.category-dropdown')) {
				setShowDropdown(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const response = await apiservice.getCategories();
				// console.log(response);
				setCategoryList(response.categories);
				setFilteredCategories(response.categories);
			} catch (error) {
				// console.error('Error fetching categories:', error);
				toast.error('Failed to fetch categories');
			}
		};

		fetchCategories();
	}, [apiservice]);

	const renderProductTypeFields = () => {
		if (!selectedProduct) return null;
		switch (productData.productType) {
			case 'Clothing':
				return (
					<Clothing
						sizes={sizes}
						setSizes={setSizes}
						colors={colors}
						setColors={setColors}
						onToggle={setToggleStates}
						productData={productData}
						handleProductDataChange={handleProductDataChange}
						toggleStates={toggleStates}
					/>
				);
			case 'Footwear':
				return (
					<Footwear
						sizes={sizes}
						setSizes={setSizes}
						colors={colors}
						setColors={setColors}
						onToggle={setToggleStates}
						productData={productData}
						handleProductDataChange={handleProductDataChange}
						toggleStates={toggleStates}
					/>
				);
			case 'Electronics':
				return (
					<Electronics
						selectedElectronics={selectedElectronics}
						setSelectedElectronics={setSelectedElectronics}
						sizes={sizes}
						setSizes={setSizes}
						onToggle={setToggleStates}
						productData={productData}
						handleProductDataChange={handleProductDataChange}
						toggleStates={toggleStates}
					/>
				);
			case 'HomeGoods':
				return (
					<Homegoods
						onToggle={setToggleStates}
						productData={productData}
						handleProductDataChange={handleProductDataChange}
						toggleStates={toggleStates}
					/>
				);
			default:
				return null;
		}
	};
	// console.log('Selected Product', selectedProduct);
	return (
		<div
			className='fixed inset-0 z-50 flex items-start justify-end bg-primary bg-opacity-70'
			onClick={closeProductModal}>
			<div
				className='bg-white shadow-lg w-[420px] h-full flex flex-col justify-between'
				onClick={(e) => e.stopPropagation()}>
				{/* Modal Header */}
				<div className='flex justify-between items-center border-b p-4'>
					<h1 className='font-medium text-[21px] text-primary'>
						Edit {selectedProduct?.name}
					</h1>
					<button
						onClick={closeProductModal}
						className='bg-gray-100 text-primary p-2 rounded-full'>
						<FaTimes />
					</button>
				</div>
				{
					<div className='text-[16px] font-medium text-primary p-4 bg-gray-100'>
						{activeTab === '1'
							? 'Product Basics'
							: 'Product Attributes'}
					</div>
				}

				{/* Modal Content */}
				<div className='flex-1 mt-5 overflow-y-auto px-4 space-y-6'>
					{activeTab === '1' && (
						<form className='flex flex-col space-y-5'>
							{[
								'name',
								'description',
								'unitPrice',
								'availableQuantity',
							].map((field) => (
								<InputField
									key={field}
									label={`Product ${field}`}
									value={productData[field]}
									handleValue={(value) => {
										handleProductDataChange(field, value);
									}}
									prefix={
										field === 'price' && (
											<div className='text-[14px] text-gray-500'>
												NGN
											</div>
										)
									}
									placeholder={`Enter product ${field}`}
									type={
										field === 'price' || field === 'stock'
											? 'number'
											: field === 'description'
											? 'textarea'
											: 'text'
									}
								/>
							))}

							<div className='relative category-dropdown'>
								<InputField
									label='Product category'
									value={searchTerm}
									handleValue={handleCategoryInput}
									type='text'
									placeholder='Type to search categories'
									className='mb-2'
								/>
								{showDropdown &&
									filteredCategories.length > 0 && (
										<ul className='absolute z-50 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-auto no-scrollbar'>
											{filteredCategories.map(
												(category, index) => (
													<li
														key={index}
														className='px-4 py-2 hover:bg-gray-100 cursor-pointer'
														onClick={() =>
															handleCategorySelect(
																category,
															)
														}>
														{category}
													</li>
												),
											)}
										</ul>
									)}
							</div>

							{/* Image Upload */}
							<div>
								<label className='font-[500] text-[14px] mb-2 block'>
									Product Images (5 Max)
								</label>
								<div className='flex space-x-2'>
									{imagePreviews.map((image, index) => (
										<div
											key={index}
											className='relative w-full h-16 rounded overflow-hidden'>
											<img
												src={image}
												alt={index}
												className='object-cover w-full h-full'
											/>
											<button
												onClick={() =>
													removeImage(index)
												}
												className='absolute top-5 right-5 bg-red-600 text-white rounded-full p-1'>
												<FaRegTrashAlt size={20} />
											</button>
										</div>
									))}
									{imagePreviews.length < 5 && (
										<label className='w-16 h-16 border-dashed border rounded cursor-pointer flex items-center justify-center'>
											<span className='text-gray-400 text-lg w-16 items-center flex justify-center'>
												+
											</span>
											<input
												type='file'
												className='hidden'
												onChange={handleImageUpload}
												accept='image/*'
												multiple
											/>
										</label>
									)}
								</div>
							</div>
						</form>
					)}
					{activeTab === '2' && (
						<div className='space-y-6'>
							<InputField
								label='Product Type'
								value={productData.productType}
								handleValue={(value) =>
									handleProductDataChange(
										'productType',
										value,
									)
								}
								placeholder='Select product type'
								type='select'
								options={[
									{
										value: 'No special attributes',
										label: 'No special attributes',
									},
									{ value: 'Clothing', label: 'Clothing' },
									{ value: 'Footwear', label: 'Footwear' },
									{
										value: 'Electronics',
										label: 'Electronics',
									},
									{ value: 'HomeGoods', label: 'HomeGoods' },
								]}
							/>
							{renderProductTypeFields()}

							<div className='mt-6 py-6 border-y'>
								<ToggleButton
									label='Does the product require production?'
									onToggle={(state) => {
										handleToggleChange('production', state);
										if (!state) {
											handleProductDataChange(
												'duration',
												'',
												true,
											);
										}
									}}
									initialState={toggleStates.production}
								/>
								{toggleStates.production && (
									<div className='flex flex-col gap-3 mt-4'>
										<InputField
											label='Duration (days)'
											value={
												productData.attributes[
													productData.productType.toLowerCase()
												]?.productionDays || ''
											}
											handleValue={(value) =>
												handleProductDataChange(
													'duration',
													value,
													true,
												)
											}
											type='number'
											placeholder='Enter number of days'
										/>
									</div>
								)}
							</div>
						</div>
					)}
				</div>

				{/* Modal Footer */}
				<div className='z-30 flex space-x-4 items-center justify-between border-t p-4'>
					<button
						className='text-gray-500 text-[14px]'
						onClick={closeProductModal}>
						Cancel
					</button>
					<div className='flex space-x-2'>
						{activeTab === '2' && (
							<Button
								label={'Previous'}
								onClick={() => {
									setActiveTab('1');
								}}
								type='tertiary'
							/>
						)}
						<Button
							label={activeTab === '1' ? 'Next' : 'Edit Product'}
							onClick={handleNextStep}
							// disabled={activeTab === '1' && !isTab1Valid()}
							className='min-w-1/3'
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditProductModal;
