import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Exit from '../../../assets/images/x.svg';
import handleCopyToClipboard from '../../../utils/copyToClipboard';
import { toast } from 'sonner';
import { FaTimes } from 'react-icons/fa';
import { TbCopy } from 'react-icons/tb';
import { useAPI } from '../../../apis/api_context';
import { SubscriptionModal } from './SubscriptionModal';
import Button from './button';
import SubscriptionNotice from './subscribeNotice';

const ShareProductModal = ({ closeShareModal, selectedProduct }) => {
	const apiservice = useAPI();
	const { customers, subscription } = useSelector((state) => state.user);

	const [selectedCustomers, setSelectedCustomers] = useState([]);
	const [inputEmails, setInputEmails] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [isSharing, setIsSharing] = useState(false);
	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] =
		useState(false);

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const isFreeTier = subscription.tier === 'Free';

	const handleSelectAll = () => {
		if (selectedCustomers.length === customers.length) {
			setSelectedCustomers([]);
		} else {
			setSelectedCustomers(customers.map((customer) => customer.email));
		}
	};

	const handleCustomerCheckboxChange = (email) => {
		setSelectedCustomers((prevSelected) =>
			prevSelected.includes(email)
				? prevSelected.filter((e) => e !== email)
				: [...prevSelected, email],
		);
	};

	useEffect(() => {
		const allSelected =
			customers.length > 0 &&
			selectedCustomers.length === customers.length;
		const selectAllCheckbox = document.getElementById(
			'select-all-checkbox',
		);
		if (selectAllCheckbox) {
			selectAllCheckbox.checked = allSelected;
		}
	}, [selectedCustomers, customers]);

	const handleShareProduct = async (e) => {
		if (isFreeTier) {
			setIsSubscriptionModalOpen(true);
			return;
		}
		setIsSharing(true);
		e.preventDefault();
		const totalRecipients = [...selectedCustomers, ...inputEmails];

		if (totalRecipients.length === 0) {
			alert('Please select at least one recipient.');
			setIsSharing(false);
			return;
		}

		const customerPayload = totalRecipients.map((email) => ({ email }));
		console.log(customerPayload);

		try {
			await apiservice.updateProductNotification(
				{ customers: customerPayload },
				selectedProduct._id,
			);
			toast.success('Product shared successfully!');
			closeShareModal();
		} catch (error) {
			toast.error('Failed to share the product.');
		} finally {
			setIsSharing(false);
		}
	};

	const handleCopyLink = () => {
		const productLink = selectedProduct.link;
		handleCopyToClipboard('Product Link', productLink);
	};

	const handleEmailInputKeyPress = (e) => {
		if (e.key === 'Enter' || e.key === ',') {
			e.preventDefault();
			const trimmedEmails = searchTerm
				.split(',')
				.map((email) => email.trim())
				.filter((email) => email);
			const newEmails = trimmedEmails.filter(
				(email) => !inputEmails.includes(email),
			);
			setInputEmails((prev) => [...prev, ...newEmails]);
			setSearchTerm('');
		}
	};

	const handleRemoveInputEmail = (email) => {
		setInputEmails(inputEmails.filter((e) => e !== email));
	};

	return (
		<div
			className='fixed inset-0 z-50 flex items-center justify-center bg-primary bg-opacity-70 font-firma'
			onClick={closeShareModal}>
			<div
				className='bg-white w-[35vw] max-h-[90%] overflow-y-scroll no-scrollbar scroll-smooth flex flex-col rounded-[12px]'
				onClick={(e) => e.stopPropagation()}>
				<div>
					<div className='flex justify-between p-4 items-start border-b'>
						<div className='space-y-1'>
							<h1 className='font-[500] text-[21px] text-primary'>
								Promote {selectedProduct?.name}
							</h1>
						</div>

						<button
							onClick={closeShareModal}
							className='bg-gray-100 text-primary p-2 rounded-full'>
							<FaTimes />
						</button>
					</div>
					{isFreeTier && (
						<SubscriptionNotice
							title='Unlock targeted marketing with our Pro Plan'
							// description='Upgrade now to gain access to advanced marketing features'
							ctaText='Upgrade Now'
							onClick={() => setIsSubscriptionModalOpen(true)}
						/>
					)}
				</div>

				<>
					<div className='flex flex-col p-4 space-y-3'>
						<h2 className='text-[14px] font-[300] text-gray-500'>
							Share via email addresses
						</h2>
						<div className='w-full py-2 px-3 bg-[#F8F8F8] rounded-[8px] flex flex-wrap items-center gap-1'>
							{inputEmails.map((email) => (
								<div
									key={email}
									className='bg-white text-primary px-[22px] py-[8px] rounded-[12px] flex items-center gap-[10px]'>
									<span>{email}</span>
									<img
										src={Exit}
										alt='Remove'
										className='w-[14px] h-[14px] cursor-pointer'
										onClick={() =>
											handleRemoveInputEmail(email)
										}
									/>
								</div>
							))}

							<input
								type='text'
								value={searchTerm}
								onChange={handleSearchChange}
								onKeyDown={handleEmailInputKeyPress}
								className='h-[48px] flex-grow outline-none rounded-lg focus:outline-none focus:border-none border-none focus:ring-0 focus:shadow-none bg-[#F8F8F8] min-w-[50px]'
							/>
						</div>
					</div>

					<div className='flex flex-col p-4 space-y-2'>
						<h2 className='text-[14px] font-[300] text-gray-500'>
							Share with Customers
						</h2>
						<div className='max-h-[35vh] overflow-y-scroll no-scrollbar scroll-smooth mb-[32px] p-1'>
							<div className='flex justify-between pb-4'>
								<div className='flex items-center space-x-2'>
									<label className='font-[500] text-[16px] text-primary'>
										Select all customers
									</label>
									<div className='bg-[#E6F7F7] text-[#43A047] w-auto h-[26px] rounded-[8px] py-1 px-2 flex items-center justify-center'>
										{selectedCustomers.length}/
										{customers.length}
									</div>
								</div>
								<input
									id='select-all-checkbox'
									type='checkbox'
									checked={
										selectedCustomers.length ===
										customers.length
									}
									onChange={handleSelectAll}
									className='form-checkbox w-[18px] h-[18px] text-basegreen bg-white border-borderNeutral rounded-[4px] focus:outline-none focus:shadow-none focus:border-none checked:bg-basegreen focus:ring-0 focus:ring-offset-0 cursor-pointer'
								/>
							</div>
							<div className='flex flex-col gap-1 rounded-[8px] py-2'>
								{customers.map((customer) => (
									<React.Fragment key={customer.email}>
										<div className='my-4 flex justify-between items-center w-full h-[40px] cursor-pointer transition duration-200 ease-in-out'>
											<div className='space-y-1 '>
												<p className='text-[16px] font-[400] items-center text-primary'>
													{customer.name}
												</p>
												<p className='text-[14px] font-[300] items-center text-gray-500'>
													{customer.email}
												</p>
											</div>
											<input
												type='checkbox'
												checked={selectedCustomers.includes(
													customer.email,
												)}
												onChange={() =>
													handleCustomerCheckboxChange(
														customer.email,
													)
												}
												className='form-checkbox w-[18px] h-[18px] text-basegreen bg-white border-borderNeutral rounded-[4px] focus:outline-none focus:shadow-none focus:border-none cursor-pointer'
											/>
										</div>
										<div className='border border-bgNeutral'></div>
									</React.Fragment>
								))}
							</div>
						</div>
					</div>

					<div className='flex justify-end space-x-3 p-4 pb-8'>
						<Button
							type='secondary'
							label='Copy Link'
							onClick={handleCopyLink}
							iconLeft={<TbCopy />}
						/>
						<Button
							type='primary'
							label={isSharing ? 'Sharing...' : 'Share'}
							onClick={handleShareProduct}
							loading={isSharing}
							disabled={
								isFreeTier ||
								(selectedCustomers.length === 0 &&
									inputEmails.length === 0)
							}></Button>
					</div>
				</>
			</div>
			<SubscriptionModal
				isOpen={isSubscriptionModalOpen}
				onClose={() => setIsSubscriptionModalOpen(false)}
				currentTier={subscription.tier}
			/>
		</div>
	);
};

export default ShareProductModal;
