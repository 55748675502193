import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	store: {},
	coupons: {},
	customers: {},
	orders: {},
	subscription: {},
	analytics: {},
	totalDeliveries: {},
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserData: (state, action) => {
			return { ...state, ...action.payload };
		},
		clearUserData: () => initialState,
	},
});

export const { setUserData, clearUserData } = userSlice.actions;

export default userSlice.reducer;
