import React from 'react';

const SubscriptionNotice = ({
	title = 'Unlock premium features',
	// description = 'Upgrade to access advanced tools and resources',
	ctaText = 'Upgrade Now',
	onClick,
	className = '',
}) => {
	return (
		<div className={`w-full justify-between flex p-4 ${className}`}>
			<div className='bg-basegreen rounded-lg w-full flex p-6 items-center space-x-10'>
				<div className='space-x-3 flex items-center'>
					<i className='fi fi-br-diamond text-white'></i>
					<div className='text-white'>
						{title && <h2 className=''>{title}</h2>}
						{/* <p>{description}</p> */}
					</div>
				</div>
				{ctaText && (
					<div
						onClick={onClick}
						className='text-textgreen cursor-pointer whitespace-nowrap'>
						{ctaText}
					</div>
				)}
			</div>
		</div>
	);
};

export default SubscriptionNotice;
