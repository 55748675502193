import React, { useEffect, useState } from 'react';
import SizeModal from './sizemodal';
import InputField from '../input_field';
import ColorModal from '../ColorModal';

const Footwear = ({
	sizes,
	setSizes,
	colors,
	setColors,
	productData,
	handleProductDataChange,
}) => {
	const [newSize, setNewSize] = useState('');
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
	const [isColorModalOpen, setIsColorModalOpen] = useState(false);
	const [selectedSizes, setSelectedSizes] = useState([]);
	const [selectedColor, setSelectedColor] = useState('');
	const availableSizes = ['38', '39', '40', '41', '42', '43', '44', 'Custom'];

	useEffect(() => {
		if (productData?.attributes?.footwear?.sizes) {
			setSelectedSizes(productData.attributes.footwear.sizes);
		}
		if (productData?.attributes?.footwear?.colors) {
			setColors(productData.attributes.footwear.colors);
		}
	}, [productData, setColors]);

	const handleColorClick = (color) => {
		setSelectedColor(color);
		const updatedAttributes = {
			...productData.attributes,
			footwear: {
				...productData.attributes.footwear,
				colors: [...colors],
			},
		};
		handleProductDataChange('attributes', updatedAttributes);
	};

	const handleSizeClick = (size) => {
		if (size === 'Custom') {
			setIsSizeModalOpen(true);
		} else {
			const updatedSizes = selectedSizes.includes(size)
				? selectedSizes.filter((s) => s !== size)
				: [...selectedSizes, size];
			setSelectedSizes(updatedSizes);

			const updatedAttributes = {
				...productData.attributes,
				footwear: {
					...productData.attributes.footwear,
					sizes: updatedSizes,
				},
			};
			handleProductDataChange('attributes', updatedAttributes);
		}
	};

	const handleAddCustomSize = () => {
		if (newSize) {
			const updatedSizes = [...selectedSizes, newSize];
			setSelectedSizes(updatedSizes);

			const updatedAttributes = {
				...productData.attributes,
				footwear: {
					...productData.attributes.footwear,
					sizes: updatedSizes,
				},
			};
			handleProductDataChange('attributes', updatedAttributes);

			setNewSize('');
			setIsSizeModalOpen(false);
		}
	};

	const handleAddColor = (color) => {
		if (color) {
			const updatedColors = [...colors, color];
			setColors(updatedColors);

			const updatedAttributes = {
				...productData.attributes,
				footwear: {
					...productData.attributes.footwear,
					colors: updatedColors,
				},
			};
			handleProductDataChange('attributes', updatedAttributes);
		}
		setIsColorModalOpen(false);
	};

	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				<InputField
					label='Brand'
					value={productData.attributes?.footwear?.brand || ''}
					handleValue={(value) =>
						handleProductDataChange('footwear.brand', value, true)
					}
					placeholder='Enter brand name'
				/>
			</div>

			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
					Available Sizes (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{availableSizes.map((size, index) => (
						<button
							type='button'
							key={index}
							onClick={() => handleSizeClick(size)}
							className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-[14px] ${
								selectedSizes.includes(size)
									? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
									: 'border-[1px] border-secondary bg-white text-gray-500'
							}`}>
							{size}
						</button>
					))}
				</div>
			</div>

			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
					Available Colors (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{colors.map((color, index) => (
						<div
							key={index}
							className={`w-8 h-8 cursor-pointer border rounded-md ${
								color === selectedColor
									? 'border-2 border-[#dfdfdf]'
									: 'border border-gray-300'
							}`}
							style={{ backgroundColor: color }}
							onClick={() => handleColorClick(color)}
						/>
					))}
					<button
						type='button'
						onClick={() => setIsColorModalOpen(true)}
						className='text-[14px] text-basegreen'>
						+add colors
					</button>
				</div>
			</div>

			{isSizeModalOpen && (
				<SizeModal
					isOpen={isSizeModalOpen}
					onClose={() => setIsSizeModalOpen(false)}
					newSize={newSize}
					setNewSize={setNewSize}
					handleAddSize={handleAddCustomSize}
					title='Add New Size'
					label='Input Footwear Size'
					placeholder='49'
					buttonText='Add Size'
				/>
			)}

			{isColorModalOpen && (
				<ColorModal
					isOpen={isColorModalOpen}
					onClose={() => setIsColorModalOpen(false)}
					onAddColor={handleAddColor}
				/>
			)}
		</div>
	);
};

export default Footwear;
