import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import ShareProductModal from '../components/shareProductModal';
import { numberWithCommas } from '../../../utils/add_commas';
import Layout from '../../../Layout';
import { RiShare2Line } from 'react-icons/ri';
import { IoArrowBack } from 'react-icons/io5';
import { FaChevronRight } from 'react-icons/fa';
import { CiEdit } from 'react-icons/ci';
import EditProductModal from '../components/productflow/EditProductModal';

const ProductDetailsPage = () => {
	const { productId } = useParams();
	const navigate = useNavigate();

	const [selectedProduct, setSelectedProduct] = useState(null);
	const [category, setCategory] = useState(null);
	const [isEditModalOpen, setEditModalOpen] = useState(false);
	const [isShareModalOpen, setShareModalOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [colorNames, setColorNames] = useState({});
	const [editType, setEditType] = useState(null);

	const userData = useSelector((state) => state.user);

	const openEditModal = (item, type) => {
		// console.log('Item to edit at Store', item);
		setEditType(type);
		setEditModalOpen(true);
	};

	useEffect(() => {
		const product = userData.store.categories
			.flatMap((category) => category.products)
			.find((item) => item._id === productId);
		if (product) {
			setSelectedProduct(product);
			setSelectedImage(product.images[0]);

			const productCategory = userData.store.categories.find((category) =>
				category.products.some((item) => item._id === productId),
			);
			setCategory(productCategory?.name);
		}
	}, [productId, userData]);

	const handleBackToProducts = () => {
		navigate(-1);
	};

	const closeEditModal = () => {
		setEditModalOpen(false);
		setEditType(null);
	};

	const openShareModal = () => {
		setShareModalOpen(true);
	};

	const closeShareModal = () => {
		setShareModalOpen(false);
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
	};

	const fetchColorName = async (colorHex) => {
		try {
			const response = await fetch(
				`https://api.color.pizza/v1/?values=${encodeURIComponent(
					colorHex.replace('#', ''),
				)}`,
			);
			const data = await response.json();
			const colorData = data.colors[0];
			setColorNames((prevColorNames) => ({
				...prevColorNames,
				[colorHex]: colorData.name,
			}));
		} catch (error) {
			console.error('Error fetching color name:', error);
		}
	};

	useEffect(() => {
		if (selectedProduct?.attributes) {
			const { type, clothing, footwear } = selectedProduct.attributes;

			let colors = [];
			if (type === 'Clothing' && clothing?.colors) {
				colors = clothing.colors;
			} else if (type === 'Footwear' && footwear?.colors) {
				colors =
					typeof footwear.colors === 'string'
						? footwear.colors
								.split(',')
								.map((color) => color.trim())
						: footwear.colors;
			}

			colors.forEach((color) => {
				if (!colorNames[color] && color.startsWith('#')) {
					fetchColorName(color);
				}
			});
		}
	}, [selectedProduct, colorNames]);

	const renderSizes = (sizes) => {
		if (!sizes) return null;

		const sizeArray = Array.isArray(sizes)
			? sizes
			: sizes.split(',').map((size) => size.trim());

		return (
			<div className='w-full flex flex-wrap gap-2'>
				{sizeArray.map((size) => (
					<div
						key={size}
						className='bg-gray-100 px-6 py-1.5 rounded-full w-fit'>
						{size}
					</div>
				))}
			</div>
		);
	};

	const renderColors = (colors) => {
		if (!colors) return null;

		const colorArray = Array.isArray(colors)
			? colors
			: colors.split(',').map((color) => color.trim());

		return (
			<div className='w-full flex flex-wrap gap-2'>
				{colorArray.map((color) => (
					<div
						key={color}
						className='flex items-center gap-2 bg-gray-100 pr-4 pl-1.5 py-1.5 rounded-full w-fit'>
						<div
							className='h-8 w-8 rounded-full border'
							style={{
								backgroundColor: color.startsWith('#')
									? color
									: '#' + color,
							}}
						/>
						<div>
							{color.startsWith('#')
								? colorNames[color] || 'Loading...'
								: color}
						</div>
					</div>
				))}
			</div>
		);
	};

	const renderAttributes = () => {
		if (!selectedProduct?.attributes) return null;

		const { type, clothing, footwear, electronics } =
			selectedProduct.attributes;

		switch (type) {
			case 'Clothing':
				return (
					<div className='flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[14px]'>
								Available Sizes
							</div>
							<div className='text-primary'>
								{renderSizes(clothing.sizes)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[14px]'>
								Available Colors
							</div>
							<div className='text-primary'>
								{renderColors(clothing.colors)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[14px]'>
								Gender
							</div>
							<div className='text-primary'>
								{clothing.gender}
							</div>
						</div>
					</div>
				);

			case 'Footwear':
				return (
					<div className='flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[14px]'>
								Available Sizes
							</div>
							<div className='text-primary'>
								{renderSizes(footwear.sizes)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[14px]'>
								Available Colors
							</div>
							<div className='text-primary'>
								{renderColors(footwear.colors)}
							</div>
						</div>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[14px]'>
								Brand
							</div>
							<div className='text-primary'>{footwear.brand}</div>
						</div>
					</div>
				);

			case 'Electronics':
				return (
					<div className='flex flex-col gap-6'>
						<div className='flex flex-col gap-2'>
							<div className='text-secondary text-[14px]'>
								Type
							</div>
							<div className='text-primary'>
								{electronics.type}
							</div>
						</div>
						{electronics.type === 'laptop' && (
							<>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[14px]'>
										RAM
									</div>
									<div className='text-primary'>
										{electronics.laptops.ram} GB
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[14px]'>
										Processor
									</div>
									<div className='text-primary'>
										{electronics.laptops.processor}
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[14px]'>
										Storage
									</div>
									<div className='text-primary'>
										{electronics.laptops.storage} GB
									</div>
								</div>
								<div className='flex flex-col gap-2'>
									<div className='text-secondary text-[14px]'>
										Operating System
									</div>
									<div className='text-primary'>
										{electronics.laptops.os}
									</div>
								</div>
							</>
						)}
					</div>
				);

			default:
				return null;
		}
	};

	return (
		<Layout>
			<div className='flex flex-col gap-6 overflow-y-scroll no-scrollbar scroll-smooth'>
				<div className='flex justify-between items-center w-full '>
					<div className='flex items-center gap-8'>
						<button onClick={handleBackToProducts}>
							<IoArrowBack size={21} />
						</button>
						<div className='flex items-center gap-2'>
							<h2
								onClick={handleBackToProducts}
								className='cursor-pointer text-[16px] leading-[18px] text-secondary'>
								Products
							</h2>
							<button className='text-secondary'>
								<FaChevronRight size={12} />
							</button>
							<h2 className='text-[16px] leading-[18px] text-primary'>
								{selectedProduct?.name}
							</h2>
						</div>
					</div>
					<div className='flex space-x-6'>
						<div className='flex items-center gap-4'>
							<button onClick={openEditModal}>
								<div className='flex text-secondary items-center space-x-3'>
									<CiEdit size={24} />
									<span>Edit</span>
								</div>
							</button>
						</div>
						<div className='flex items-center gap-4'>
							<button onClick={openShareModal}>
								<div className='flex text-secondary items-center space-x-3'>
									<RiShare2Line size={24} />
									<span>Share</span>
								</div>
							</button>
						</div>
					</div>
				</div>

				<div className='grid grid-cols-2 gap-8'>
					<div className='flex flex-col space-y-4 w-full '>
						<img
							src={selectedImage}
							alt={`Product ${selectedProduct?.name}`}
							className='rounded-[12px] w-full h-[65vh] object-cover border border-gray-300'
						/>
						<div className='w-full flex space-x-4 items-center overflow-x-auto pb-2'>
							{selectedProduct?.images?.map((image, index) => (
								<img
									key={index}
									src={image}
									alt={`Product ${index + 1}`}
									onClick={() => handleImageClick(image)}
									className={`h-[109px] w-[109px] rounded-[8px] flex-shrink-0 cursor-pointer ${
										selectedImage === image
											? 'border-2 border-primary'
											: 'border border-gray-300'
									}`}
								/>
							))}
						</div>
					</div>

					<div className='flex flex-col items-start'>
						<div className='flex justify-center items-center rounded-[8px] py-[4px] px-[8px] bg-[#E6F7F7] w-auto h-[28px] text-[#43A047] text-[16px] leading-[20px] mb-[31px]'>
							{category}
						</div>
						<div className='space-y-8'>
							<h1 className='text-[40px] leading-[46px] text-primary mb-[43px]'>
								{selectedProduct?.name}
							</h1>
							<div className='flex flex-col gap-2 '>
								<h2 className='text-[14px] text-secondary'>
									Product Price
								</h2>
								<p className='text-[21px] font-medium text-primary'>
									NGN{' '}
									{numberWithCommas(
										selectedProduct?.unitPrice,
									)}
								</p>
							</div>
							<div className='flex flex-col gap-2 '>
								<h2 className='text-[14px] leading-[18px] text-secondary'>
									Product Description
								</h2>
								<p className='text-[16px] text-primary'>
									{selectedProduct?.description}
								</p>
							</div>

							<div className='flex flex-col gap-2'>
								<h2 className='text-[14px] text-secondary'>
									Available Quantity
								</h2>
								<p className='text-[18px] leading-[21px] text-primary'>
									{selectedProduct?.availableQuantity} Units
								</p>
							</div>

							{renderAttributes()}
						</div>
					</div>
				</div>
			</div>

			{/* {isEditModalOpen && (
				<EditProductModal
					closeProductModal={closeEditModal}
					selectedProduct={selectedProduct}
					modalName={'Edit Product'}
					modalButton={'Save Changes'}
				/>
			)} */}

			{isEditModalOpen && (
				<EditProductModal
					closeProductModal={closeEditModal}
					selectedProduct={selectedProduct}
					type={editType}
				/>
			)}

			{isShareModalOpen && (
				<ShareProductModal
					closeShareModal={closeShareModal}
					selectedProduct={selectedProduct}
				/>
			)}
		</Layout>
	);
};

export default ProductDetailsPage;
