import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import { toast } from "sonner";
import { useAPI } from "../../../apis/api_context";
import InputField from "./input_field";
import Button from "./button";
import { LinearProgress } from "@mui/material";
import SubscriptionNotice from "./subscribeNotice";
import { useSelector } from "react-redux";
import { SubscriptionModal } from "./SubscriptionModal";
import { useAuth } from "../../../apis/auth_context";

const CouponModal = ({ closeCouponModal }) => {
  const { refreshUserData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [generatingCoupon, setGeneratingCoupon] = useState(false);
  const [code, setCode] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [expiry, setExpiry] = useState("");
  const [maxUsage, setMaxUsage] = useState("");
  const [expiryType, setExpiryType] = useState("This coupon doesn't expire");
  const [couponType, setCouponType] = useState("");
  const apiservice = useAPI();
  const { subscription } = useSelector((state) => state.user);
  const isFreeTier = subscription.tier === "Free";
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);

  const generateCode = async () => {
    if (isFreeTier) {
      setIsSubscriptionModalOpen(true);
      return;
    }

    setGeneratingCoupon(true);
    try {
      const generatedCode = await apiservice.generateCouponCode();
      if (generatedCode.status === 200) {
        setCode(generatedCode.data);
        toast.success("Coupon Code generated successfully");
      }
    } catch (error) {
      toast.error("Failed to generate coupon code");
    } finally {
      setGeneratingCoupon(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await apiservice.createCoupon(
        code,
        discountValue,
        expiry,
        maxUsage,
        couponType
      );

      await refreshUserData();
      toast.success("Coupon created successfully", {
        autoClose: 3000,
      });

      closeCouponModal();
    } catch (error) {
      toast.error("Failed to create coupon");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-start justify-end bg-primary bg-opacity-70"
      onClick={closeCouponModal}
    >
      <div
        className="bg-white shadow-lg w-[420px] h-full flex flex-col justify-between"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center border-b p-4">
          <h1 className="font-medium text-[21px] text-primary">
            Create New Coupon
          </h1>
          <button
            onClick={closeCouponModal}
            className="bg-gray-100 text-primary p-2 rounded-full"
          >
            <FaTimes />
          </button>
        </div>
        {isFreeTier && (
          <SubscriptionNotice
            title="Improve loyalty with our Pro Plan"
            // description='Upgrade now to gain access to advanced marketing features'
            ctaText="Upgrade Now"
            onClick={() => setIsSubscriptionModalOpen(true)}
          />
        )}
        <div className="flex-1 mt-5 overflow-y-auto px-4 space-y-6">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col space-y-3 justify-end">
              <InputField
                label="Coupon Code"
                placeholder="Enter coupon code"
                value={code}
                handleValue={setCode}
                type="text"
                className="w-full"
              />
              <button
                type="button"
                className="flex justify-end text-basegreen font-medium text-sm"
                onClick={generateCode}
              >
                {generatingCoupon ? <LinearProgress /> : "Generate code for me"}
              </button>
            </div>
            {/* Coupon Type Dropdown */}
            <InputField
              label="Coupon Type"
              value={couponType}
              handleValue={setCouponType}
              placeholder="Select coupon type"
              type="select"
              options={[
                {
                  value: "delivery",
                  label: "Delivery Discount",
                },
                {
                  value: "first-time",
                  label: "New Customer Offer",
                },
                {
                  value: "promotion",
                  label: "Promotional Offer",
                },
                { value: "holiday", label: "Holiday Special" },
                { value: "clearance", label: "Clearance Sale" },

                { value: "loyalty", label: "Loyalty Reward" },

                {
                  value: "shipping",
                  label: "Shipping Discount",
                },
              ]}
              className="w-full"
            />

            {/* Discount Field */}
            <InputField
              label="Discount %"
              placeholder="0"
              value={discountValue}
              handleValue={(value) => setDiscountValue(value)}
              type="number"
              className="w-full"
            />

            {/* Expiry Settings */}
            <h2 className="font-medium text-primary text-base">
              Set Coupon Expiry
            </h2>
            <p className="text-sm text-gray-500">
              Set an expiration date for your coupon. You can manually
              deactivate it anytime in your dashboard.
            </p>

            {/* Expiry Type Selection Dropdown */}
            <InputField
              label="Expiry Type"
              value={expiryType}
              handleValue={(value) => {
                setExpiryType(value);
                setExpiry("");
                setMaxUsage("");
              }}
              placeholder="Select expiry type"
              type="select"
              options={[
                {
                  value: "This coupon doesn't expire",
                  label: "This coupon doesn't expire",
                },
                { value: "Date", label: "Date" },
                {
                  value: "Value (Number used)",
                  label: "Value (Number used)",
                },
              ]}
              className="w-full"
            />

            {/* Conditional Inputs based on selected expiry type */}
            {expiryType === "Date" && (
              <InputField
                label="Enter Expiration Date"
                value={expiry}
                handleValue={setExpiry}
                type="date"
                placeholder="Select date"
                className="w-full"
              />
            )}
            {expiryType === "Value (Number used)" && (
              <InputField
                label="Usage Limit"
                value={maxUsage}
                handleValue={setMaxUsage}
                type="number"
                placeholder="Enter max usage"
                className="w-full"
              />
            )}
          </div>
        </div>
        <div className="z-20 flex space-x-4 items-center justify-between border-t p-4">
          <button
            className="text-gray-500 text-[14px]"
            onClick={closeCouponModal}
          >
            Cancel
          </button>
          <Button
            label={"Create Coupon code"}
            onClick={handleSubmit}
            disabled={isFreeTier || loading}
            loading={loading}
            className="w-fit"
          />
        </div>
      </div>
      <SubscriptionModal
        isOpen={isSubscriptionModalOpen}
        onClose={() => setIsSubscriptionModalOpen(false)}
        currentTier={subscription.tier}
      />
    </div>
  );
};

export default CouponModal;
