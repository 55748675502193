import React, { useEffect, useRef } from 'react';

const Dropdown = ({
	actions,
	onClose,
	position,
	width = 'w-full',
	space = 'gap-2',
	padding = 'p-2',
}) => {
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

	return (
		<div
			ref={dropdownRef}
			className={`absolute ${position} ${width} bg-white border border-gray-200 rounded-lg shadow-lg z-30`}
			style={{ minWidth: '40px' }}
			onClick={(e) => e.stopPropagation()}>
			<div
				className={`flex flex-col ${width} items-center ${space} ${padding} max-h-[240px] overflow-y-auto overflow-hidden`}>
				{actions?.map((action, index) => (
					<button
						key={index}
						className={`w-full bg-bgNeutral rounded-[4px] p-3 text-[500] text-primary text-[16px] leading-[18px] -tracking-[3%] text-left`}
						onClick={() => {
							action.onClick();
							onClose();
						}}>
						{action.label}
					</button>
				))}
			</div>
		</div>
	);
};

export default Dropdown;
