import React, { useEffect, useState } from 'react';
import SizeModal from './sizemodal';
import InputField from '../input_field';

const Electronics = ({
	sizes,
	setSizes,
	productData,
	handleProductDataChange,
}) => {
	const [newSize, setNewSize] = useState('');
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
	const [selectedSizes, setSelectedSizes] = useState(
		() => productData.attributes.sizes || [],
	);
	const [currentCategory, setCurrentCategory] = useState('');

	useEffect(() => {
		setSelectedSizes(productData.attributes.electronics?.sizes || []);
	}, [productData]);

	const handleSizeClick = (size, category) => {
		if (size === 'Custom') {
			setCurrentCategory(category);
			setIsSizeModalOpen(true);
		} else {
			const updatedSizes = selectedSizes.includes(size)
				? selectedSizes.filter((s) => s !== size)
				: [...selectedSizes, size];
			setSelectedSizes(updatedSizes);
			handleUpdateProductData(updatedSizes, category);
		}
	};

	const handleAddSize = () => {
		if (newSize) {
			const updatedSizes = {
				...sizes,
				[currentCategory]: sizes[currentCategory]
					? [...sizes[currentCategory], newSize]
					: [newSize],
			};
			setSizes(updatedSizes);
			setNewSize('');
			setIsSizeModalOpen(false);
			const newSelectedSizes = [...selectedSizes, newSize];
			setSelectedSizes(newSelectedSizes);
			handleUpdateProductData(newSelectedSizes, currentCategory);
		}
	};

	const handleUpdateProductData = (updatedSizes) => {
		const updatedAttributes = {
			...productData.attributes,
			electronics: {
				...productData.attributes.electronics,
				sizes: updatedSizes,
			},
		};
		handleProductDataChange('attributes', updatedAttributes);
	};

	// const handleUpdateProductData = (updatedSizes, category = null) => {
	// 	const updatedAttributes = {
	// 		...productData.attributes,
	// 		electronics: {
	// 			...productData.attributes.electronics,
	// 			...(category
	// 				? {
	// 						[category.toLowerCase()]: {
	// 							sizes: updatedSizes,
	// 						},
	// 				  }
	// 				: {
	// 						sizes: updatedSizes,
	// 				  }),
	// 		},
	// 	};
	// 	handleProductDataChange('attributes', updatedAttributes);
	// };

	const renderSizeModal = () => {
		let modalProps = {};
		switch (currentCategory) {
			case 'laptop':
				modalProps = {
					title: 'Add Laptop Size',
					label: 'Input laptop size',
					placeholder: '15 inches',
					buttonText: 'Add Laptop Size',
				};
				break;
			case 'phone':
				modalProps = {
					title: 'Add Phone Size',
					label: 'Input phone size',
					placeholder: '6 inches',
					buttonText: 'Add Phone Size',
				};
				break;
			case 'camera':
				modalProps = {
					title: 'Add Camera Type',
					label: 'Input camera type',
					placeholder: 'DSLR',
					buttonText: 'Add Camera Type',
				};
				break;
			case 'tv':
				modalProps = {
					title: 'Add TV Size',
					label: 'Input Size',
					placeholder: '80',
					buttonText: 'Add Size',
				};
				break;
			default:
				break;
		}

		return (
			<SizeModal
				isOpen={isSizeModalOpen}
				onClose={() => setIsSizeModalOpen(false)}
				newSize={newSize}
				setNewSize={setNewSize}
				handleAddSize={handleAddSize}
				{...modalProps}
			/>
		);
	};

	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				<InputField
					label='Type'
					value={
						productData.attributes.electronics.type ||
						'Select Electronics Type'
					}
					handleValue={(value) =>
						handleProductDataChange('electronics.type', value, true)
					}
					type='select'
					options={[
						{ label: 'Laptop', value: 'laptop' },
						{ label: 'TV', value: 'TV' },
						{ label: 'Smartphones', value: 'smartphone' },
						{ label: 'Camera', value: 'camera' },
					]}
				/>
			</div>

			<div>
				{productData.attributes.electronics.type === 'laptop' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
								Available Screen Sizes (optional)
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.laptop?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'laptop')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-[14px] ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-gray-500'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						{/* Additional input fields for laptops */}
						<div className='flex flex-col gap-3'>
							<div className='flex gap-3'>
								<InputField
									label='RAM (GB)'
									value={
										productData.attributes.electronics
											.laptop.ram || ''
									}
									handleValue={(value) =>
										handleProductDataChange(
											'electronics.laptop.ram',
											value,
											true,
										)
									}
									placeholder='Ex. 4'
								/>
								<InputField
									label='Processor'
									value={
										productData.attributes.electronics
											.laptop.processor || ''
									}
									handleValue={(value) =>
										handleProductDataChange(
											'electronics.laptop.processor',
											value,
											true,
										)
									}
									placeholder='Ex. Intel'
								/>
							</div>
							<InputField
								label='Storage (GB)'
								value={
									productData.attributes.electronics.laptop
										.storage || ''
								}
								handleValue={(value) =>
									handleProductDataChange(
										'electronics.laptop.storage',
										value,
										true,
									)
								}
								placeholder='Ex. 512'
							/>
							<InputField
								label='Operating System'
								value={
									productData.attributes.electronics.laptop
										.os || ''
								}
								handleValue={(value) =>
									handleProductDataChange(
										'electronics.laptop.os',
										value,
										true,
									)
								}
								type='select'
								options={[
									{ label: 'Windows', value: 'Windows' },
									{ label: 'macOS', value: 'macOS' },
									{ label: 'Linux', value: 'Linux' },
									{ label: 'Chrome OS', value: 'Chrome OS' },
								]}
								placeholder={'Select Operating System'}
							/>
						</div>
					</div>
				)}

				{productData.attributes.electronics.type === 'TV' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
								Available Screen Sizes (optional)
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.tv?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'tv')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-[14px] ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-gray-500'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						<InputField
							label='Model'
							value={
								productData.attributes.electronics.tv.model ||
								''
							}
							handleValue={(value) =>
								handleProductDataChange(
									'electronics.tv.model',
									value,
									true,
								)
							}
							placeholder='Ex. LG'
						/>
					</div>
				)}

				{productData.attributes.electronics.type === 'smartphone' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
								Available Screen Sizes (optional)
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.smartphone?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'smartphone')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-[14px] ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-gray-500'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						<InputField
							label='Model'
							value={
								productData.attributes.electronics.smartphone
									.model || ''
							}
							handleValue={(value) =>
								handleProductDataChange(
									'electronics.smartphone.model',
									value,
									true,
								)
							}
							placeholder='Ex. iPhone 13'
						/>
					</div>
				)}

				{productData.attributes.electronics.type === 'camera' && (
					<div className='flex flex-col gap-10'>
						<div className='flex flex-col gap-3'>
							<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
								Available Camera Types (optional)
							</h3>
							<div className='flex flex-wrap gap-2'>
								{sizes.camera?.map((size, index) => (
									<button
										type='button'
										key={index}
										onClick={() =>
											handleSizeClick(size, 'camera')
										}
										className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-[14px] ${
											selectedSizes.includes(size)
												? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
												: 'border-[1px] border-secondary bg-white text-gray-500'
										}`}>
										{size}
									</button>
								))}
							</div>
						</div>
						<InputField
							label='Model'
							value={
								productData.attributes.electronics.camera
									.model || ''
							}
							handleValue={(value) =>
								handleProductDataChange(
									'electronics.camera.model',
									value,
									true,
								)
							}
							placeholder='Ex. Canon'
						/>
					</div>
				)}
			</div>
			{renderSizeModal()}
		</div>
	);
};

export default Electronics;
