import React, { useState } from 'react';

const ToggleButton = ({ label, onToggle, initialState }) => {
	const [isToggled, setIsToggled] = useState(initialState);

	const handleToggle = () => {
		const newToggleState = !isToggled;
		setIsToggled(newToggleState);
		if (onToggle) {
			onToggle(newToggleState);
		}
	};

	return (
		<div className='flex items-center justify-between'>
			<span>{label}</span>
			<div
				className={`ml-2 w-10 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer transition-colors duration-300 ${
					isToggled ? 'bg-green-500' : ''
				}`}
				onClick={handleToggle}>
				<div
					className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ${
						isToggled ? 'translate-x-4' : ''
					}`}></div>
			</div>
		</div>
	);
};

export default ToggleButton;
