import React, { useEffect, useState } from 'react';
import SizeModal from './sizemodal';
import InputField from '../input_field';
import ColorModal from '../ColorModal';

const Clothing = ({
	sizes,
	setSizes,
	colors,
	setColors,
	productData,
	handleProductDataChange,
}) => {
	const [newSize, setNewSize] = useState('');
	const [isSizeModalOpen, setIsSizeModalOpen] = useState(false);
	const [isColorModalOpen, setIsColorModalOpen] = useState(false);

	// Fixed list of available sizes
	const availableSizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', 'Custom'];

	// Initialize selectedSizes from productData
	const [selectedSizes, setSelectedSizes] = useState(() => {
		if (productData?.attributes?.clothing?.sizes) {
			return productData.attributes.clothing.sizes;
		}
		return [];
	});

	// Initialize colors from productData
	useEffect(() => {
		if (productData?.attributes?.clothing?.colors) {
			setColors(productData.attributes.clothing.colors);
		}
	}, [productData, setColors]);

	const handleColorClick = (color) => {
		const updatedAttributes = {
			...productData.attributes,
			clothing: {
				...productData.attributes.clothing,
				colors: [...colors, color],
			},
		};
		handleProductDataChange('attributes', updatedAttributes);
	};

	const handleSizeClick = (size) => {
		if (size === 'Custom') {
			setIsSizeModalOpen(true);
		} else {
			// Toggle size selection
			const updatedSizes = selectedSizes.includes(size)
				? selectedSizes.filter((s) => s !== size)
				: [...selectedSizes, size];

			setSelectedSizes(updatedSizes);

			// Update the product data with the new sizes
			const updatedAttributes = {
				...productData.attributes,
				clothing: {
					...productData.attributes.clothing,
					sizes: updatedSizes,
				},
			};
			handleProductDataChange('attributes', updatedAttributes);
		}
	};

	const handleAddCustomSize = (category) => {
		if (newSize) {
			// Add custom size to selected sizes
			const updatedSizes = [...selectedSizes, newSize];
			setSelectedSizes(updatedSizes);

			// Update the product data
			const updatedAttributes = {
				...productData.attributes,
				clothing: {
					...productData.attributes.clothing,
					sizes: updatedSizes,
				},
			};
			handleProductDataChange('attributes', updatedAttributes);

			setNewSize('');
			setIsSizeModalOpen(false);
		}
	};

	const handleAddColor = (color) => {
		const updatedColors = [...colors, color];
		setColors(updatedColors);
		setIsColorModalOpen(false);

		const updatedAttributes = {
			...productData.attributes,
			clothing: {
				...productData.attributes.clothing,
				colors: updatedColors,
			},
		};
		handleProductDataChange('attributes', updatedAttributes);
	};

	const handleGenderChange = (value) => {
		const updatedAttributes = {
			...productData.attributes,
			clothing: {
				...productData.attributes.clothing,
				gender: value,
			},
		};
		handleProductDataChange('attributes', updatedAttributes);
	};

	return (
		<div className='flex flex-col gap-10'>
			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
					Available Sizes (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{availableSizes.map((size, index) => (
						<button
							type='button'
							key={index}
							onClick={() => handleSizeClick(size)}
							className={`px-[24px] py-[8px] rounded-[30px] cursor-pointer text-[14px] ${
								selectedSizes.includes(size)
									? 'border-[1px] border-bordergreen bg-textgreen text-bordergreen'
									: 'border-[1px] border-secondary bg-white text-gray-500'
							}`}>
							{size}
						</button>
					))}
				</div>
			</div>

			<div className='flex flex-col gap-3'>
				<h3 className='font-[500] text-[14px] leading-[16px] -tracking-[3%] text-gray-500'>
					Available Colors (optional)
				</h3>
				<div className='flex flex-wrap gap-2'>
					{colors.map((color, index) => (
						<div
							key={index}
							className={`w-8 h-8 cursor-pointer border rounded-md ${
								colors.includes(color)
									? 'border-2 border-[#dfdfdf]'
									: 'border border-gray-300'
							}`}
							style={{ backgroundColor: color }}
							onClick={() => handleColorClick(color)}
						/>
					))}
					<button
						type='button'
						onClick={() => setIsColorModalOpen(true)}
						className='text-[14px] text-basegreen'>
						+add colors
					</button>
				</div>
			</div>

			<InputField
				label='Gender'
				value={productData.attributes?.clothing?.gender || ''}
				handleValue={handleGenderChange}
				type='select'
				options={[
					{ label: 'Male', value: 'Male' },
					{ label: 'Female', value: 'Female' },
					{ label: 'Unisex', value: 'Unisex' },
				]}
			/>

			{isSizeModalOpen && (
				<SizeModal
					isOpen={isSizeModalOpen}
					onClose={() => setIsSizeModalOpen(false)}
					newSize={newSize}
					setNewSize={setNewSize}
					handleAddSize={() => handleAddCustomSize('cloth')}
					title='Add New Size'
					label='Input Cloth Size'
					placeholder='4XL'
					buttonText='Add Size'
				/>
			)}

			{isColorModalOpen && (
				<ColorModal
					isOpen={isColorModalOpen}
					onClose={() => setIsColorModalOpen(false)}
					onAddColor={handleAddColor}
				/>
			)}
		</div>
	);
};

export default Clothing;
