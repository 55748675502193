import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { HexColorPicker } from 'react-colorful';
import Button from './button';

const ColorModal = ({ isOpen, onClose, onAddColor }) => {
	const [newColor, setNewColor] = useState('#FFFFFF');
	const [colorName, setColorName] = useState('');

	const fetchColorName = async (colorHex) => {
		try {
			const response = await fetch(
				`https://api.color.pizza/v1/?values=${colorHex}`,
			);
			const data = await response.json();
			const colorData = data.colors[0];
			setColorName(colorData.name);
		} catch (error) {
			console.error('Error fetching color name:', error);
		}
	};

	useEffect(() => {
		if (newColor) {
			fetchColorName(newColor.replace('#', ''));
		}
	}, [newColor]);

	const handleColorInputChange = (value) => {
		if (value.startsWith('#') && value.length <= 7) {
			setNewColor(value);
		}
	};

	const handleResetColor = () => {
		const defaultColor = '#FFFFFF';
		setNewColor(defaultColor);
	};

	const handleAddColor = () => {
		onAddColor(newColor);
	};

	if (!isOpen) return null;

	return (
		<div
			className='fixed z-40 inset-0 flex items-center justify-center bg-primary bg-opacity-50'
			onClick={onClose}>
			<div
				className='bg-white p-4 rounded-lg w-[30vw]'
				onClick={(e) => e.stopPropagation()}>
				<div className='flex justify-between mb-8 items-center'>
					<h1 className='text-[21px] text-primary'>Add New Color</h1>
					<button
						onClick={onClose}
						className='bg-gray-100 text-primary p-2 rounded-full'>
						<FaTimes />
					</button>
				</div>

				<div className='flex justify-center items-center w-48 h-48 rounded overflow-hidden mx-auto'>
					<HexColorPicker
						color={newColor}
						onChange={setNewColor}
						className='border'
					/>
				</div>

				<div className='mt-4'>
					<h4 className='text-gray-500 font-[500] text-[14px]'>
						Selection
					</h4>
					<div className='overflow-hidden border-t border-b flex justify-between w-full py-[12px] px-[12px] border-borderNeutral'>
						<div className='flex gap-1 items-center'>
							<div
								className='w-8 h-8 border rounded-lg'
								style={{ backgroundColor: newColor }}
							/>
							<input
								type='text'
								value={newColor}
								onChange={(e) =>
									handleColorInputChange(e.target.value)
								}
								className='w-full font-[500] text-[14px] leading-[16px] text-gray-500 border-none focus:outline-none'
							/>
						</div>
						<button
							type='button'
							onClick={handleResetColor}
							className='text-basegreen'>
							Reset
						</button>
					</div>
					<p className='mt-4 text-[14px] text-gray-500'>
						Color Name: {colorName}
					</p>
				</div>

				<Button
					label='Add Color'
					onClick={handleAddColor}
					type='primary'
					className='w-full mt-8'
				/>
			</div>
		</div>
	);
};

export default ColorModal;
