import React, { useCallback, useEffect, useState } from 'react';
import { useAPI } from '../../../apis/api_context';
import Avatar from './avatar';
import StatusChip from './statusChips';
import Button from './button';
import orderBox from '../../../assets/images/order-box.png';
import { toast } from 'sonner';
import { useAuth } from '../../../apis/auth_context';
import { FaTimes } from 'react-icons/fa';

const OrderModal = ({ order, onClose }) => {
	const apiService = useAPI();
	const { refreshUserData } = useAuth();
	const [orderDetails, setOrderDetails] = useState({});
	const [loading, setLoading] = useState(true);
	const [isScrolled, setIsScrolled] = useState(false);
	const [isLoading, setIsLoaidng] = useState(false);

	const fetchOrderDetails = useCallback(async () => {
		setLoading(true);
		try {
			const response = await apiService.getOrderDetails(order._id);
			setOrderDetails(response);
		} catch (error) {
		} finally {
			setLoading(false);
		} 
	}, [apiService, order._id]);
	useEffect(() => {
		fetchOrderDetails();
	}, [apiService, fetchOrderDetails, order._id]);

	const handleScroll = (e) => {
		if (e.target.scrollTop > 100) {
			setIsScrolled(true);
		} else {
			setIsScrolled(false);
		}
	};
	const updateOrderStatus = async (orderId, orderStatus) => {
		setIsLoaidng(true);
		try {
			await apiService.updateOrderStatus(orderId, orderStatus);
			fetchOrderDetails();
			refreshUserData();
		} catch (error) {
			toast(error);
		} finally {
			setIsLoaidng(false);
		}
	};

	const getActionButton = (status, orderId) => {
		switch (status) {
			case 'Order Created':
				return (
					<Button
						label={'Order Ready'}
						onClick={() =>
							updateOrderStatus(orderId, 'Order Ready')
						}
						className='w-full'
						loading={isLoading}
					/>
				);

			case 'Order Return In Transit':
				return (
					<Button
						label={'Products Received'}
						onClick={() => {
							updateOrderStatus(orderId, 'Order Return Received');
							onClose();
						}}
						className='w-full'
						loading={isLoading}
					/>
				);
			default:
				return null;
		}
	};

	if (loading) {
		return (
			<div className='fixed inset-0 flex items-center justify-center bg-primary bg-opacity-50 z-50'>
				<div className='bg-white rounded-lg w-[35vw]'>
					<p className='text-center text-lg font-semibold text-gray-700'>
						Loading order details...
					</p>
				</div>
			</div>
		);
	}

	return (
		<div
			onClick={onClose}
			className='fixed inset-0 flex items-center justify-center bg-primary bg-opacity-50 z-50'>
			<div
				onClick={(e) => e.stopPropagation()}
				className='bg-white rounded-lg overflow-clip w-[37.5vw] relative max-h-[75vh] flex flex-col'>
				{/* Fixed Header */}
				<div className='flex justify-between px-4 py-4 border-b border-gray-100 sticky w-full bg-white z-10'>
					<div className='text-[21px] text-primary flex space-x-2 items-center'>
						<img
							src={orderBox}
							alt=''
							className='h-[24px] w-[24px]'
						/>
						<p>Order {orderDetails?.customId}</p>
					</div>
					<button
						onClick={onClose}
						className='bg-gray-100 text-primary p-2 rounded-full'>
						<FaTimes />
					</button>
				</div>

				{/* Status Chip and Dynamic Chips */}
				<div className='p-4 bg-white sticky w-full z-10 flex items-center space-x-2 border-b'>
					<div className='max-w-[50%] truncate'>
						<StatusChip status={orderDetails?.currentStatus} />
					</div>
					{isScrolled && (
						<div className='w-[50%] flex space-x-2 '>
							<div className='bg-gray-200 max-w-[50%] text-gray-700 truncated rounded-full px-4 py-2 truncate'>
								{orderDetails?.customer?.name}
							</div>
							<div className='bg-gray-200 max-w-[50%] text-gray-700 rounded-full px-4 py-2 truncate'>
								{orderDetails?.courier?.name || 'Pending'}
							</div>
						</div>
					)}
				</div>

				{/* Scrollable content */}
				<div
					className='overflow-y-auto flex-grow pb-[24px] no-scrollbar'
					onScroll={handleScroll}>
					<div className='px-4'>
						{/* Customer Info */}
						<div className='py-4 border-b border-gray-100'>
							<h3 className='text-[12px] font-[300] text-basegreen'>
								Customer
							</h3>
							<div className='flex items-center mt-2'>
								<Avatar
									firstName={
										orderDetails?.customer?.name ||
										order.customer
									}
									imageSrc={orderDetails?.customer?.image}
								/>
								<span className='ml-2 text-gray-700'>
									{orderDetails?.customer?.name ||
										order.customer}
								</span>
							</div>
						</div>

						{/* Courier Info */}
						<div className='py-4 border-b border-gray-100'>
							<h3 className='text-[12px] font-[300] text-basegreen'>
								Courier
							</h3>
							{orderDetails?.courier ? (
								<div className='flex items-center mt-2'>
									<Avatar
										firstName={orderDetails?.courier?.name}
										imageSrc={orderDetails?.courier?.image}
									/>
									<span className='ml-2 text-gray-700'>
										{orderDetails?.courier?.name}
									</span>
								</div>
							) : (
								<div className='flex items-center mt-2'>
									<Avatar firstName={'AC'} />
									<span className='ml-2 text-gray-700'>
										Awaiting Courier Assignment
									</span>
								</div>
							)}
						</div>

						{/* Products List */}
						<div className='py-6'>
							<h3 className='text-[12px] font-[300] text-basegreen'>
								Items
							</h3>
							<div className='mt-3 space-y-2'>
								{orderDetails?.products?.map((item, index) => (
									<div
										key={index}
										className='flex justify-between border-b py-3'>
										<p className='text-gray-700'>
											{item.product.name}
										</p>
										<div className='flex space-x-2 items-center'>
											<p className='text-[14px] font-light text-gray-500'>
												Quantity:{' '}
											</p>
											<div className='font-medium text-primary bg-[#F0F0F0] h-[24px] w-[24px] rounded-full flex items-center justify-center'>
												{item.quantity}
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>

				{/* Fixed Button at the bottom */}
				{orderDetails?.currentStatus !== 'Order Created' ||
					(orderDetails?.currentStatus !==
						'Order Return In Transit' && (
						<div className='px-4 py-4 border-t border-gray-100 bg-white sticky bottom-0 z-10'>
							{getActionButton(
								orderDetails?.currentStatus,
								orderDetails?._id,
							)}
						</div>
					))}
			</div>
		</div>
	);
};

export default OrderModal;
