import React from 'react';
import StatusChip from './statusChips';

const ActiveOrders = ({ activeOrders }) => {
	return (
		<div className='font-firma overflow-y-scroll no-scrollbar scroll-smooth bg-[#FAFAFA] p-5 rounded-[12px] flex flex-col gap-3 items-center'>
			{activeOrders?.map((order, index) => (
				<div
					key={order.customId}
					className='w-full flex flex-col gap-2'>
					<div className='flex justify-between gap-5 items-center w-full'>
						<h2 className='font-[500] text-[16px] leading-[18px] -tracking-[3%] text-gray-500'>
							{order.customId}
						</h2>
						{/* <div className=''>
							{order.currentStatus === 'Order Created' && (
								<OrderButton
									textColor='#333333'
									backgroundColor='#f5f5f5'
									orderText={order.currentStatus}
								/>
							)}

							{order.currentStatus === 'Order Delivered' && (
								<OrderButton
									textColor='#43A047'
									backgroundColor='#E6F7F7'
									orderText={order.currentStatus}
								/>
							)}
							{order.currentStatus === 'Order Ready' && (
								<OrderButton
									textColor='#8A6906'
									backgroundColor='#FFF2E8'
									orderText={order.currentStatus}
								/>
							)}
							{order.currentStatus === 'Out for Delivery' && (
								<OrderButton
									textColor='#007BFF'
									backgroundColor='#E6F7F7'
									orderText={order.currentStatus}
								/>
							)}
							{order.currentStatus === 'Failed Delivery' && (
								<OrderButton
									textColor='#DC3545'
									backgroundColor='#FDEEEF'
									orderText={order.currentStatus}
								/>
							)}
						</div> */}
						<div className=''>
							{/* Use StatusChip component to display the status */}
							<StatusChip status={order.currentStatus} />
						</div>
					</div>
					{index < activeOrders.length - 1 && (
						<div className='border-b w-full border-borderNeutral mb-[6px]'></div>
					)}
				</div>
			))}
		</div>
	);
};

export default ActiveOrders;
